import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import ContactForm from '../components/form/Contact';

const ContactPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/contact-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Contact | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`Vous avez un projet ? Contactez ${query.site.siteMetadata.title}, notre équipe met tout en œuvre afin de réaliser vos projets de plâtrerie, peinture, aménagement, décoration, carrelage, faïence, sols souples, façade et isolation thermique par l'intérieure.`}
      pageKeywords="contact, décoration, aménagement, sols souples, isolation intérieure, isolation extérieure"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Contactez-nous</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Contact</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="office-info">
                <div className="row">
                  {query.site.siteMetadata.addressGoogleDirection &&
                    <div className="col col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                      <div className="office-info-item h-100">
                        <div className="office-info-icon">
                          <div className="icon">
                            <i className="bi bi-geo-alt"></i>
                          </div>
                        </div>
                        <div className="office-info-text">
                          <h2>Adresse</h2>
                          <p>
                            <a href={query.site.siteMetadata.addressGoogleDirection} target="_blank" rel="noopener noreferrer" aria-label="address direction">
                              {query.site.siteMetadata.addressStreet &&
                                <em>{query.site.siteMetadata.addressStreet} {query.site.siteMetadata.addressPostalCode} {query.site.siteMetadata.addressCity}</em>
                              }
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  {query.site.siteMetadata.telephone &&
                    <div className="col col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                      <div className="office-info-item h-100">
                        <div className="office-info-icon">
                          <div className="icon">
                            <i className="bi bi-telephone"></i>
                          </div>
                        </div>
                        <div className="office-info-text">
                          <h2>Téléphone</h2>
                          <p><a href={"tel:" + query.site.siteMetadata.telephone}>{query.site.siteMetadata.telephone}</a></p>
                        </div>
                      </div>
                    </div>
                  }
                  {query.site.siteMetadata.email &&
                    <div className="col col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                      <div className="office-info-item h-100">
                        <div className="office-info-icon">
                          <div className="icon">
                            <i className="bi bi-envelope"></i>
                          </div>
                        </div>
                        <div className="office-info-text">
                          <h2>E-mail</h2>
                          <p><a href={"mailto:" + query.site.siteMetadata.email}>{query.site.siteMetadata.email}</a></p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="office-info">
                <div className="wpo-contact-title">
                  <h2>Suivez-nous</h2>
                </div>
                <div>
                  {query.site.siteMetadata.socialNetworks.facebook &&
                    <div className="row justify-content-center">
                      {query.site.siteMetadata.socialNetworks.facebook &&
                        <div className="col-auto">
                          <a href={query.site.siteMetadata.socialNetworks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                            <div className="office-info-social">
                              <div className="icon">
                                <i className="bi bi-facebook"></i>
                              </div>
                            </div>
                          </a>                                  
                        </div>
                      }
                      {query.site.siteMetadata.socialNetworks.instagram &&
                        <div className="col-auto">
                          <a href={query.site.siteMetadata.socialNetworks.instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram">
                            <div className="office-info-social">
                              <div className="icon">
                                <i className="bi bi-instagram"></i>
                              </div>
                            </div>
                          </a>                                  
                        </div>
                      }
                      {query.site.siteMetadata.socialNetworks.linkedIn &&
                        <div className="col-auto">
                          <a href={query.site.siteMetadata.socialNetworks.linkedIn} target="_blank" rel="noopener noreferrer" aria-label="linkedin">
                            <div className="office-info-social">
                              <div className="icon">
                                <i className="bi bi-linkedin"></i>
                              </div>
                            </div>
                          </a>                                  
                        </div>
                      }    
                    </div>
                  }
                </div>
              </div>
              <div className="wpo-contact-title">
                <h2>Vous avez une question ?</h2>
                <p>Contactez-nous pour voir comment nous pouvons vous aider dans votre projet.</p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div> 
      </section>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe
            src="https://maps.google.com/maps?q=1%20Imp.%20de%20la%20Marne,%2042300%20Roanne%20mousse&t=&z=13&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            title={query.site.siteMetadata.title}
          />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
